import AccountTuple from './Account/index';
import AlertsTuple from './Alerts/index';
import ComingSoonTuple from './ComingSoon/index';
import CommonTuple from './Common/index';
import CreateAccountTuple from './CreateAccount/index';
import CybersecurityTuple from './Cybersecurity/index';
import DashboardTuple from './Dashboard/index';
import ErrorsTuple from './Errors/index';
import FooterTuple from './Footer/index';
import ForgotPasswordTuple from './ForgotPassword/index';
import LabelsTuple from './Labels/index';
import LinkServiceTuple from './LinkService/index';
import MFATuple from './MFA/index';
import ModalsTuple from './Modals/index';
import NoMatchTuple from './NoMatch/index';
import OnboardingTuple from './Onboarding/index';
import SecurityQuestionsTuple from './SecurityQuestions/index';
import SignInTuple from './SignIn/index';

describe('Content Translations: ', () => {
  it('Account Elements have the same keys', () => {
    expect(checkObjectKeys(AccountTuple)).toBeTruthy();
  });
  it('Alert Elements have the same keys', () => {
    expect(checkObjectKeys(AlertsTuple)).toBeTruthy();
  });
  it('ComingSoon Elements have the same keys', () => {
    expect(checkObjectKeys(ComingSoonTuple)).toBeTruthy();
  });
  it('Common Elements have the same keys', () => {
    expect(checkObjectKeys(CommonTuple)).toBeTruthy();
  });
  it('CreateAccount Elements have the same keys', () => {
    expect(checkObjectKeys(CreateAccountTuple)).toBeTruthy();
  });
  it('Cybersecurity Elements have the same keys', () => {
    expect(checkObjectKeys(CybersecurityTuple)).toBeTruthy();
  });
  it('Dashboard Elements have the same keys', () => {
    expect(checkObjectKeys(DashboardTuple)).toBeTruthy();
  });
  it('Error Elements have the same keys', () => {
    expect(checkObjectKeys(ErrorsTuple)).toBeTruthy();
  });
  it('Footer Elements have the same keys', () => {
    expect(checkObjectKeys(FooterTuple)).toBeTruthy();
  });
  it('ForgotPassword Elements have the same keys', () => {
    expect(checkObjectKeys(ForgotPasswordTuple)).toBeTruthy();
  });
  it('Label Elements have the same keys', () => {
    expect(checkObjectKeys(LabelsTuple)).toBeTruthy();
  });
  it('LinkService elements have the same keys', () => {
    expect(checkObjectKeys(LinkServiceTuple)).toBeTruthy();
  });
  it('MFA Elements have the same keys', () => {
    expect(checkObjectKeys(MFATuple)).toBeTruthy();
  });
  it('Modal Elements have the same keys', () => {
    expect(checkObjectKeys(ModalsTuple)).toBeTruthy();
  });
  it('NoMatch Elements have the same keys', () => {
    expect(checkObjectKeys(NoMatchTuple)).toBeTruthy();
  });
  it('Onboarding Elements have the same keys', () => {
    expect(checkObjectKeys(OnboardingTuple)).toBeTruthy();
  });
  it('Security Questions Elements have the same keys', () => {
    expect(checkObjectKeys(SecurityQuestionsTuple)).toBeTruthy();
  });
  it('SignIn Elements have the same keys', () => {
    expect(checkObjectKeys(SignInTuple)).toBeTruthy();
  });
});

function checkObjectKeys(tuple) {
  const file_en = tuple.find(obj => obj.language === 'en').object;
  const file_es = tuple.find(obj => obj.language === 'es').object;

  if (!file_en || !file_es) {
    return false;
  }

  if (Object.keys(file_en).length !== Object.keys(file_es).length) {
    return false;
  }

  const sorted_en_keys = Object.keys(sortObject(file_en));
  const sorted_es_keys = Object.keys(sortObject(file_es));

  let incorrectKeys = [];

  sorted_en_keys.forEach((key, i) => {
    if (sorted_en_keys[i] !== sorted_es_keys[i]) {
      incorrectKeys.push(key);
    }
  });

  if (incorrectKeys.length) return false;

  return true;
}

function sortObject(unSortedObject) {
  const sortedObject = {};
  Object.keys(unSortedObject)
    .sort()
    .forEach(key => {
      sortedObject[key] = unSortedObject[key];
    });
  return sortedObject;
}
