// Vendors
import { lazy } from 'react';
import PAGE_TYPES from '../pageTypes';

export default [
    {
        type: PAGE_TYPES.TAKEOVER,
        name: 'CybersecurityPage',
        component: lazy(() => import('./Cybersecurity/CybersecurityPage')),
    },
];
