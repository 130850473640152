import * as CPA from './CPA';
import * as CAPPS from './CAPPS';
//import * as TCEQ from './TCEQ';

//16 or 452 or 304 is the agency code (agency_CD) from https://fmx.cpa.texas.gov/fm/contacts/agynum/index.php
// slug is the agency name link for the data file??
//27 or TDLR_LR, CPA is the application code(APPLICATION_CD)
//agencyCode:
//slug: str
//appCode: str

export const agencySlugMap = {
  '452': {
    slug: 'tdlr',
    TDLR_LR: 'tdlrlr',
  },
  '304': {
    slug: 'cpa',
    CPA_LN: 'cpaln',
  },
  '999': {
    slug: 'capps',
    CAPPS_LN: 'cappsln',
  },
  '313': {
    slug: 'dir',
    CAPPS_LN: 'dirln',
  },
  '003580': {
    slug: 'kcl',
    CAPPS_LN: 'kclln',
  },
  '009990': {
    slug: 'tdis',
    CAPPS_LN: 'tdisln',
  },
  '009163': {
    slug: 'alamo',
    CAPPS_LN: 'alamoln',
  },
  '403': {
    slug: 'tvc',
    CAPPS_LN: 'tvdln',
  },
  '356': {
    slug: 'tec',
    CAPPS_LN: 'tecln',
  },
  '790': {
    slug: 'dir',
    CAPPS_LN: 'dirln',
  },
  '466': {
    slug: 'occc',
    CAPPS_LN: 'occcln'
  },
  '582': {
    slug: 'tceq',
    CAPPS_LN: 'tceqln'
  },
  '970': {
    slug: 'dir',
    CAPPS_LN: 'dirln'
  },
  '455': {
    slug: 'rrc',
    CAPPS_LN: 'rrc'
  },
  '401': {
    slug: 'tmd',
    CAPPS_LN: 'tmdln'
  },
  '475': {
    slug: 'opuc',
    CAPPS_LN: 'opucln'
  },
  '735': {
    slug: 'msu',
    CAPPS_LN: 'msuln'
  },
  '696': {
    slug: 'tdcj',
    CAPPS_LN: 'TDCJ_LN'
  },
  '000701': {
    slug: 'semarc',
    CAPPS_LN: 'SEMARC'
  },
  '000644': {
    slug: 'semarctjjd',
    CAPPS_LN: 'SEMARCTJJD'
  }
};

//need add TCEQ obj to Agency data
export const agencyData = { ...CPA, ...CAPPS };
